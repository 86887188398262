import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_APPOINTMENT, SET_APPOINTMENTS, SET_ERRORMSG, SET_CALENDAR_APPOINTMENTS } from '../mutation_types';
import {
    GET_APPOINTMENT,
    GET_APPOINTMENTS,
    GET_CALENDAR_APPOINTMENTS,
    ADD_APPOINTMENT,
    EDIT_APPOINTMENT,
    DELETE_APPOINTMENT,
    CLEAR_APPOINTMENTS_ISLOADING,
    EDIT_APPOINTMENT_STATUS,
    REQUEST_APPOINTMENT,
    CLEAR_APPOINTMENTS,
    CLEAR_APPOINTMENTS_METADATA,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import Customer from '@/services/appointment'
import { AddAppointmentDto, EditAppointmentDto, GetAppointmentDto, RequestAppointmentDto } from '@/dtos/appointment.dto'
import Appointment from '../../services/appointment';

const appointment: Module<any, {}> = {
    state: {
        appointment: {
            data: null,
            isLoading: false
        },
        appointments: {
            data: null,
            isLoading: false,
            metaData: null
        },
        calendarAppointments: {
            data: null,
            isLoading: false
        },
    },
    getters: {
        appointment: (state: { appointment: any }) => state.appointment,
        appointments: (state: { appointments: any }) => state.appointments,
        calendarAppointments: (state: { calendarAppointments: any }) => state.calendarAppointments
    },
    mutations: {
        [SET_APPOINTMENT]: (state: { appointment: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.appointment.data = payload.data : [], state.appointment.isLoading = payload.isLoading },
        [SET_APPOINTMENTS]: (state: { appointments: StateObject }, payload: { data: null; metaData: null, isLoading: any }) => { payload.data != null ? state.appointments.data = payload.data : null, state.appointments.isLoading = payload.isLoading, payload.metaData != null ? state.appointments.metaData = payload.metaData : state.appointments.metaData = null },
        [SET_CALENDAR_APPOINTMENTS]: (state: { calendarAppointments: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.calendarAppointments.data = payload.data : null, state.calendarAppointments.isLoading = payload.isLoading },
    },
    actions: {
        [GET_APPOINTMENTS]: async({ commit }: any, payload: GetAppointmentDto) => {
            commit(SET_APPOINTMENTS, { metaData: null, isLoading: true })

            let result: AxiosResponse = await Appointment.getAppointments(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_APPOINTMENTS, { data: result.data.data, isLoading: false, metaData: result.data.metaData })
                    return result.data.data
                }
                commit(SET_APPOINTMENTS, { data: null, metaData: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_APPOINTMENTS, { isLoading: false })
            }
            return null
        },
        [GET_APPOINTMENT]: async({ commit }: any, payload: any) => {
            commit(SET_APPOINTMENT, { data: null, isLoading: true })

            let result: AxiosResponse = await Appointment.getAppointments(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_APPOINTMENT, { data: result.data.data[0], isLoading: false })
                    return
                }
                commit(SET_APPOINTMENT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_APPOINTMENT, { isLoading: false })
            }
        },
        [GET_CALENDAR_APPOINTMENTS]: async({ commit }: any, payload: GetAppointmentDto) => {
            commit(SET_CALENDAR_APPOINTMENTS, { data: null, isLoading: true })

            let result: AxiosResponse = await Appointment.getCalendarAppointments(payload as GetAppointmentDto)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_CALENDAR_APPOINTMENTS, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_CALENDAR_APPOINTMENTS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CALENDAR_APPOINTMENTS, { isLoading: false })
            }
            return []
        },
        [ADD_APPOINTMENT]: async({ commit }: any, payload: AddAppointmentDto) => {
            commit(SET_APPOINTMENT, { data: null, isLoading: true })

            let result: AxiosResponse = await Appointment.addAppointment(payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_APPOINTMENT, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_APPOINTMENT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_APPOINTMENT, { isLoading: false })
            }
            return null
        },
        [REQUEST_APPOINTMENT]: async({ commit }: any, payload: RequestAppointmentDto) => {
            commit(SET_APPOINTMENT, { data: null, isLoading: true })

            let result: AxiosResponse = await Appointment.requestAppointment(payload)
            console.log('REQUEST_APPOINTMENT result', result)
            if(result) {
                if (result.data.status) {
                    commit(SET_APPOINTMENT, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_APPOINTMENT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_APPOINTMENT, { isLoading: false })
            }
            return null
        },
        [EDIT_APPOINTMENT_STATUS]: async({ commit }: any, payload: EditAppointmentDto) => {
            commit(SET_APPOINTMENT, { data: null, isLoading: true })

            let result: AxiosResponse = await Appointment.changeAppointmentStatus(payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_APPOINTMENT, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_APPOINTMENT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_APPOINTMENT, { isLoading: false })
            }
            return null
        },
        [DELETE_APPOINTMENT]: async({ commit }: any, payload: GetAppointmentDto) => {
            commit(SET_APPOINTMENT, { data: null, isLoading: true })

            let result: AxiosResponse = await Appointment.deleteAppointment(payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_APPOINTMENT, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_APPOINTMENT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_APPOINTMENT, { isLoading: false })
            }
            return null
        },
        [CLEAR_APPOINTMENTS_METADATA]: ({ commit }) => {
            commit(SET_APPOINTMENTS, { metaData: null })
        },
        [CLEAR_APPOINTMENTS_ISLOADING]: ({ commit }) => {
            commit(SET_APPOINTMENT, { isLoading: false })
            commit(SET_APPOINTMENTS, { isLoading: false })
            commit(SET_CALENDAR_APPOINTMENTS, { isLoading: false })
        },
        [CLEAR_APPOINTMENTS]: ({ commit }) => {
            commit(SET_APPOINTMENT, { data: null, isLoading: false })
            commit(SET_APPOINTMENTS, { data: null, metaData: null, isLoading: false })
            commit(SET_CALENDAR_APPOINTMENTS, { data: null, isLoading: false })
        }
    }
}

export default appointment