import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_COMPANY_INFO, SET_MY_COMPANY_INFO, SET_ERRORMSG, SET_MY_COMPANIES, SET_ACCOUNT, SET_SESSION_TOKEN } from '../mutation_types'
import {
    GET_COMPANY_INFO,
    GET_MY_COMPANY_INFO,
    EDIT_MY_COMPANY_INFO,
    CLEAR_COMPANY_INFO_ISLOADING,
    GET_MY_COMPANIES,
    SWITCH_COMPANY,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import Company from '@/services/company'
import { AddCompanyDto, EditCompanyDto, GetCompanyDto } from '@/dtos/company.dto'

const company: Module<any, {}> = {
    state: {
        companyInfo: {
            data: null,
            isLoading: false
        },
        myCompanyInfo: {
            data: null,
            isLoading: false
        },
        myCompanies: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        companyInfo: (state: { companyInfo: any }) => state.companyInfo,
        myCompanyInfo: (state: { myCompanyInfo: any }) => state.myCompanyInfo,
        myCompanies: (state: { myCompanies: any }) => state.myCompanies
    },
    mutations: {
        [SET_COMPANY_INFO]: (state: { companyInfo: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.companyInfo.data = payload.data : null, state.companyInfo.isLoading = payload.isLoading },
        [SET_MY_COMPANY_INFO]: (state: { myCompanyInfo: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.myCompanyInfo.data = payload.data : null, state.myCompanyInfo.isLoading = payload.isLoading },
        [SET_MY_COMPANIES]: (state: { myCompanies: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.myCompanies.data = payload.data : null, state.myCompanies.isLoading = payload.isLoading }
    },
    actions: {
        [GET_COMPANY_INFO]: async({ commit }: any, payload: GetCompanyDto) => {
            commit(SET_COMPANY_INFO, { data: null, isLoading: true })

            let result: AxiosResponse = await Company.getCompanyInfo(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_COMPANY_INFO, { data: result.data.data[0], isLoading: false })
                    return
                }
                commit(SET_COMPANY_INFO, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_COMPANY_INFO, { isLoading: false })
            }
        },
        [GET_MY_COMPANY_INFO]: async({ commit }: any, payload: GetCompanyDto) => {
            commit(SET_MY_COMPANY_INFO, { data: null, isLoading: true })

            let result: AxiosResponse = await Company.getMyCompanyInfo(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_MY_COMPANY_INFO, { data: result.data.data[0], isLoading: false })
                    return
                }
                commit(SET_MY_COMPANY_INFO, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_MY_COMPANY_INFO, { isLoading: false })
            }
        },
        [GET_MY_COMPANIES]: async({ commit }: any, payload: GetCompanyDto) => {
            commit(SET_MY_COMPANIES, { data: null, isLoading: true })

            let result: AxiosResponse = await Company.getMyCompanies(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_MY_COMPANIES, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_MY_COMPANIES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_MY_COMPANIES, { isLoading: false })
            }
            return null
        },
        [SWITCH_COMPANY]: async({ commit, dispatch }: any, payload: any) => {
            commit(SET_ACCOUNT, {  isLoading: true })

            let result: AxiosResponse = await Company.switchCompany(payload as EditCompanyDto)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_ACCOUNT, { data: result.data.data, isLoading: false })
                    commit(SET_SESSION_TOKEN, result.data.sessionToken)
                    dispatch(GET_MY_COMPANIES)
                    return result.data.data
                }
                commit(SET_ACCOUNT, { isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
            }
            return null
        },
        [EDIT_MY_COMPANY_INFO]: async({ commit }: any, payload: AddCompanyDto) => {
            commit(SET_MY_COMPANY_INFO, { data: null, isLoading: true })

            let result: AxiosResponse = await Company.editMyCompanyInfo(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_MY_COMPANY_INFO, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_MY_COMPANY_INFO, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_MY_COMPANY_INFO, { isLoading: false })
            }
            return null
        },
        [CLEAR_COMPANY_INFO_ISLOADING]: ({ commit }) => {
            commit(SET_MY_COMPANY_INFO, { isLoading: false })
        }
    }
}

export default company