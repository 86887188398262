import baseAPI from '@/api/base';
import { RequestOTPCode, VerifyOTP } from '@/dtos/otp_verification.dto';
import { CHECK_OTP_EXPIRY_URL, OTP_REQUEST_URL, OTP_VERIFICATION_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class OTPVerification {
    static requestOTPCode = async(requestOTPCode: RequestOTPCode) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(OTP_REQUEST_URL, {
                    ...requestOTPCode
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static verifyOTPCode = async(verifyOTPCode: VerifyOTP) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(OTP_VERIFICATION_URL, {
                    ...verifyOTPCode
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static checkOTPExpiry = async(verifyOTPCode: VerifyOTP) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(CHECK_OTP_EXPIRY_URL, {
                    ...verifyOTPCode
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }
}

export default OTPVerification