import { DATETIME_OPTIONS, DATE_OPTIONS, WEEK_DAYS, MONTHS, TIME_OPTIONS } from '@/utils/const';
import moment from 'moment';

const filters = {
    firstLetter(value: string) {
        return value != null ? value[0] : ''
    },

    capitalize(value: string) {
        return value != null ? value.charAt(0).toUpperCase() + value.slice(1) : '';
    },

    uppercase(value: string) {
        return value != null ? value.toString().toUpperCase() : ''
    },

    getDateOnly(value: string) {
        return value != null ? new Date(value).toLocaleDateString("en-US", DATE_OPTIONS) : '-'
    },

    getDateAndTime(value: string) {
        return value != null ? new Date(value).toLocaleDateString("en-US", DATETIME_OPTIONS) : '-'
    },

    getTimeOnly(value: string) {
        return value != null ? new Date(value).toLocaleTimeString("en-US", TIME_OPTIONS) : '-'
    },

    getDateBreakdown(value: string) {
        let date: Date = new Date(value)
        return value != null ? {
            day: WEEK_DAYS[date.getDay()],
            date: date.getDate(),
            month: MONTHS[date.getMonth()],
            year: date.getFullYear()
        } : '-'
    },
}

export default filters