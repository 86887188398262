import baseAPI from '@/api/base';
import { EDIT_COMPANY_INFO_URL, GET_COMPANY_INFO_URL, GET_MY_COMPANIES_URL, GET_MY_COMPANY_INFO_URL, SWITCH_COMPANY_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { AddCompanyDto, EditCompanyDto, GetCompanyDto } from '@/dtos/company.dto';

class Company {
    static getCompanyInfo = async(getCompanyDto: GetCompanyDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_COMPANY_INFO_URL, {
                    params: {
                        ...getCompanyDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static getMyCompanyInfo = async(getCompanyDto: GetCompanyDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_MY_COMPANY_INFO_URL, {
                    params: {
                        ...getCompanyDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static getMyCompanies = async(getCompanyDto: GetCompanyDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_MY_COMPANIES_URL, {
                    params: {
                        ...getCompanyDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static switchCompany = async(getCompanyDto: EditCompanyDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(SWITCH_COMPANY_URL, {
                    ...getCompanyDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static editMyCompanyInfo = async(addCompanyDto: AddCompanyDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_COMPANY_INFO_URL, {
                    ...addCompanyDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }
}

export default Company