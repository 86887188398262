import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_NOTIFICATION, SET_ERRORMSG } from '../mutation_types'
import {
    GET_NOTIFICATION,
    CLEAR_NOTIFICATION_ISLOADING,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import Notification from '@/services/notification'
import asyncHandler from '@/utils/async_handler'

const notification: Module<any, {}> = {
    state: {
        notification: {
            data: null,
            isLoading: false
        },
    },
    getters: {
        notification: (state: { notification: any }) => state.notification
    },
    mutations: {
        [SET_NOTIFICATION]: (state: { notification: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.notification.data = payload.data : null, state.notification.isLoading = payload.isLoading },
    },
    actions: {
        [GET_NOTIFICATION]: async({ commit }: any, payload: any) => {
            commit(SET_NOTIFICATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Notification.getUnreadNotification()
            console.log('noti resullll', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_NOTIFICATION, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_NOTIFICATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_NOTIFICATION, { isLoading: false })
            }
            return null
        },
        [CLEAR_NOTIFICATION_ISLOADING]: ({ commit }) => {
            commit(SET_NOTIFICATION, { isLoading: false })
        }
    }
}

export default notification