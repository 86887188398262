export const LARGE_SCREEN_SIZE = 992
export const AXIOS_TIMEOUT = 30000

export const SERVER_ERR_MSG = 'Server Error!'

export const DEFAULT_ERROR_MSG = 'Error! Something went wrong.'
export const TOAST_GLOBAL_CONFIG = { position: 'top-right', dismissible: true }

export const LOCAL_STORAGE_KEY = 'local'

export const DATE_OPTIONS : Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
export const TIME_OPTIONS : Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
export const DATETIME_OPTIONS : Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

export const APPOINTMENT_TIME_FORMAT_SPECIFIC_TIME: string = 'SPECIFIC_TIME'
export const APPOINTMENT_TIME_FORMAT_TIME_SECTION: string = 'DAY_SEGMENT'

export const DEFAULT_PAGINATION_LIMIT: number = 20

export const TIME_SECTION_SELECTION: Array<String> = [
    'MORNING',
    'NOON',
    'AFTERNOON',
    'NIGHT',
    'MIDNIGHT'
]

export const WEEK_DAYS: Array<String> = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednsday',
    'Thursday',
    'Friday',
    'Saturday'
]

export const MONTHS: Array<String> = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

export const APPOINTMENT_STATUSES = [
    'PENDING',
    'REQUESTED',
    'CANCELLED',
    'NO_SHOW',
    'COMPLETED'
]

export enum ACCOUNT_ROLES {
    root = 'root', 
    superAdmin = 'superAdmin', 
    admin = 'admin', 
    coordinator = 'coordinator',
    serviceProvider = 'service-provider'
}

export const CUSTOMER_DETAILS_TAB = 'details'
export const CUSTOMER_APPOINTMENTS_TAB = 'appointment'
export const CUSTOMER_SMS_TAB = 'sms'

export const STAFF_DETAILS_TAB = 'details'
export const STAFF_APPOINTMENTS_TAB = 'appointment'
export const STAFF_ACTIVITIES_TAB = 'activities'
export const STAFF_SETTINGS_TAB = 'sms'

export const APPOINTMENT_VIEW_CALENDAR = 'APPOINTMENT_VIEW_CALENDAR'
export const APPOINTMENT_VIEW_LIST = 'APPOINTMENT_VIEW_LIST'

export const STARTER_PLAN_ID = '650eac519fa2445b19633cb4'
export const PROFESSIONAL_PLAN_ID = '654938d43d2636c18f2cff13'

export const OTP_RESEND_TIMER_IN_SECS = 60

export const INVOICE_DIR = 'https://api.ketero.app/invoice'

export const PAYMENT_SOURCES = {
    ANDROID_APP: 'ANDROID_APP',
    IOS_APP: 'IOS_APP',
    WEB_APP: 'WEB_APP'
}

export const APPOINTMENT_TIME_INTERVALS = [
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM'
]

