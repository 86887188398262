import baseAPI from '@/api/base';
import { GET_STAFFS_URL, ADD_STAFF_URL, EDIT_STAFF_URL, DELETE_STAFF_URL, GET_APPOINTMENTS_URL, TOGGLE_STAFF_STATUS_URL, GET_ACTIVITIES_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { AddStaffDto, EditStaffDto, GetStaffDto } from '@/dtos/staff.dto';
import { GetAppointmentDto } from '@/dtos/appointment.dto';
import { GetActivityDto } from '@/dtos/activity.dto';

class Staff {
    static getStaffs = async(getStaffDto: GetStaffDto) : Promise<AxiosResponse> => {

        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_STAFFS_URL, {
                    params: {
                        ...getStaffDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static getStaffAppointments = async(getAppointmentDto: GetAppointmentDto) : Promise<AxiosResponse> => {

        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_APPOINTMENTS_URL, {
                    params: {
                        ...getAppointmentDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static getStaffActivities = async(getActivityDto: GetActivityDto) : Promise<AxiosResponse> => {

        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_ACTIVITIES_URL, {
                    params: {
                        ...getActivityDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static addStaff = async(addStaffDto: AddStaffDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_STAFF_URL, {
                    ...addStaffDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static editStaff = async(editStaffDto: EditStaffDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_STAFF_URL, {
                    ...editStaffDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static toggleStaffStatus = async(editStaffDto: EditStaffDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(TOGGLE_STAFF_STATUS_URL, {
                    ...editStaffDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static deleteStaff = async(getStaffDto: GetStaffDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_STAFF_URL}/${getStaffDto.id}`)
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }
}

export default Staff