import axios, { AxiosResponse } from 'axios'
import router from '@/router'
import asyncHandler from '@/utils/async_handler'
import { SET_ACCOUNT, SET_SESSION_TOKEN, SET_ERRORMSG } from '../mutation_types'
import { LOGIN_URL, LOGOUT_URL } from '@/utils/urls'
import {
    LOGIN,
    LOGOUT,
    LOGOUT_LOCALLY,
    EDIT_ACCOUNT,
    GET_AUTH_ACCOUNT,
    CLEAR_ACCOUNT_ISLOADING,
    UPDATE_ACCOUNT,
    SIGNUP,
    CLEAR_SERVICES,
    CLEAR_STAFFS,
    CLEAR_STATS,
    CLEAR_SUBSCRIPTIONS,
    CLEAR_CUSTOMERS,
    CLEAR_APPOINTMENTS
} from '../action_types'
import { LOGIN_ROUTE } from '@/utils/routes'
import { StateObject } from '@/dtos/state'
import Auth from '@/services/auth'
import { LoginCredentals, RegistrationCredentals } from '@/dtos/auth.dto'
import { Module } from 'vuex'
import { EditAccountDto } from '@/dtos/account.dto'
// import { getDeviceInfo } from '@/utils/device'
// import { AUTH_ALLOWED_ROLES } from '@/utils/const'
// import { ROLE_NOT_PERMITTED } from '@/utils/error_msgs'

const account: Module<any, {}> = {
    state: {
        account: {
            data: null,
            status: null,
            isLoading: false
        },
        sessionToken: null
    },
    getters: {
        account: (state: { account: any }) => state.account,
        sessionToken: (state: { sessionToken: any }) => state.sessionToken
    },
    mutations: {
        [SET_ACCOUNT]: (state: { account: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.account.data = payload.data : null, state.account.status = payload.status, state.account.isLoading = payload.isLoading },
        [SET_SESSION_TOKEN]: (state: { sessionToken: any }, payload: any) => { state.sessionToken = payload }
    },
    actions: {
        [LOGIN]: async({ commit }: any, payload: LoginCredentals) => {
            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.login(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_SESSION_TOKEN, result.data.sessionToken)
                    commit(SET_ACCOUNT, { data: result.data.data, isLoading: false })
                    router.go(0)
                    return
                }
                commit(SET_ACCOUNT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
            }
        },
        [SIGNUP]: async({ commit }: any, payload: any) => {
            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.signup(payload as RegistrationCredentals)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_SESSION_TOKEN, result.data.sessionToken)
                    commit(SET_ACCOUNT, { data: result.data.data, isLoading: false })
                    router.go(0)
                    return
                }
                commit(SET_ACCOUNT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
            }
        },
        [GET_AUTH_ACCOUNT]: async({ state, commit }: any, payload: any) => {
            if(state.account != null && state.sessionToken != null) {
                commit(SET_ACCOUNT, { isLoading: true })

                let result: AxiosResponse = await Auth.getAuthAccount()

                if(result) {
                    if (result.data.status) {
                        commit(SET_ACCOUNT, { data: result.data.data, isLoading: false })
                        return
                    }
                    commit(SET_ACCOUNT, { isLoading: false })
                    commit(SET_ERRORMSG, result.data.msg)
                } else {
                    commit(SET_ACCOUNT, { isLoading: false })
                }
            }
        },
        [EDIT_ACCOUNT]: async({ commit }: any, payload: any) => {
            commit(SET_ACCOUNT, { isLoading: true })

            let result: AxiosResponse = await Auth.editAccount(payload as EditAccountDto)

            if(result) {
                if (result.data.status) {
                    commit(SET_ACCOUNT, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_ACCOUNT, { isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
            }
            return null
        },
        [LOGOUT]: async({ commit, dispatch }) => {
            commit(SET_ACCOUNT, { isLoading: true })
                // const deviceInfo = await getDeviceInfo()
            const result = await Auth.logout()

            console.log('RRRR', result)

            if (result.data.status) {
                commit(SET_ACCOUNT, { data: null, isLoading: false })
                commit(SET_SESSION_TOKEN, null)
                dispatch(CLEAR_APPOINTMENTS)
                dispatch(CLEAR_CUSTOMERS)
                dispatch(CLEAR_SERVICES)
                dispatch(CLEAR_STAFFS)
                dispatch(CLEAR_STATS)
                dispatch(CLEAR_SUBSCRIPTIONS)
                router.go(0)
                return
            }
            commit(SET_ERRORMSG, result.data.msg)
            dispatch(LOGOUT_LOCALLY)

        },
        [LOGOUT_LOCALLY]: async({ commit, dispatch }) => {
            commit(SET_ACCOUNT, { data: null, isLoading: false })
            commit(SET_SESSION_TOKEN, null)
            dispatch(CLEAR_APPOINTMENTS)
            dispatch(CLEAR_CUSTOMERS)
            dispatch(CLEAR_SERVICES)
            dispatch(CLEAR_STAFFS)
            dispatch(CLEAR_STATS)
            dispatch(CLEAR_SUBSCRIPTIONS)
            router.push({ name: LOGIN_ROUTE })
        },
        [CLEAR_ACCOUNT_ISLOADING]: ({ commit }) => {
            commit(SET_ACCOUNT, { isLoading: false })
        }
    }
}

export default account