import { createStore } from "vuex";
import createVuexPersistedState from 'vuex-persistedstate';
import { LOCAL_STORAGE_KEY } from "@/utils/const";
import account from './modules/account';
import appointment from './modules/appointment';
import category from './modules/category';
import company from './modules/company';
import customer from './modules/customer';
import error from './modules/error';
import notification from './modules/notification';
import otpVerification from './modules/otp_verification';
import payment from './modules/payment';
import service from './modules/service';
import staff from './modules/staff';
import stats from './modules/stats';
import subscription from './modules/subscription';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    appointment,
    category,
    company,
    customer,
    error,
    notification,
    otpVerification,
    payment,
    service,
    subscription,
    staff,
    stats
  },
  plugins: [
    createVuexPersistedState({
        key: LOCAL_STORAGE_KEY,
        storage: window.localStorage
    })
  ]
});
