// account
export const LOGIN = 'login'
export const SIGNUP = 'signup'
export const LOGOUT = 'logout'
export const LOGOUT_LOCALLY = 'logoutLocally'
export const GET_AUTH_ACCOUNT = 'getAuthAccount'
export const UPDATE_ACCOUNT = 'updateAccount'
export const RESET_ACCOUNT_ISLOADING = 'resetAccountIsLoading'
export const EDIT_ACCOUNT = 'editAccount'
export const CHANGE_EMAIL = 'changeEmail'
export const CHANGE_PASSWORD = 'changePassword'
export const CLEAR_ACCOUNT_ISLOADING = 'clearAccountIsLoading'

// appointment
export const GET_APPOINTMENTS = 'getAppointments';
export const GET_CALENDAR_APPOINTMENTS = 'getCalendarAppointments';
export const GET_APPOINTMENT = 'getAppointment';
export const ADD_APPOINTMENT = 'addAppointment';
export const REQUEST_APPOINTMENT = 'requestAppointment';
export const EDIT_APPOINTMENT = 'editAppointment';
export const EDIT_APPOINTMENT_STATUS = 'editAppointmentStatus';
export const DELETE_APPOINTMENT = 'deleteAppointment';
export const CLEAR_APPOINTMENTS_ISLOADING = 'clearAppointmentsIsLoading';
export const CLEAR_APPOINTMENTS_METADATA = 'clearAppointmentsMetaData';
export const CLEAR_APPOINTMENTS = 'clearAppointments';

// customer
export const GET_CUSTOMERS = 'getCustomers';
export const GET_CUSTOMER = 'getCustomer';
export const ADD_CUSTOMER = 'addCustomer';
export const EDIT_CUSTOMER = 'editCustomer';
export const DELETE_CUSTOMER = 'deleteCustomer';
export const REQUEST_VERIFICATION = 'requestVerification';
export const GET_CUSTOMER_APPOINTMENTS = 'getCustomerAppointments';
export const GET_CUSTOMER_APPOINTMENTS_ADMIN = 'getCustomerAppointmentsAdmin';
export const GET_CUSTOMER_SMS_ADMIN = 'getCustomerSMSAdmin';
export const CLEAR_CUSTOMERS_ISLOADING = 'clearCustomersIsLoading';
export const CLEAR_CUSTOMERS_METADATA = 'clearCustomersMetaData';
export const CLEAR_CUSTOMER = 'clearCustomer';
export const CLEAR_CUSTOMERS = 'clearCustomers';

// service
export const GET_SERVICES = 'getServices';
export const GET_SERVICES_PUBLIC = 'getServicesPublic';
export const GET_SERVICE = 'getService';
export const ADD_SERVICE = 'addService';
export const EDIT_SERVICE = 'editService';
export const DELETE_SERVICE = 'deleteService';
export const CLEAR_SERVICES_ISLOADING = 'clearServicesIsLoading';
export const CLEAR_SERVICES = 'clearServices';

// category
export const GET_CATEGORIES = 'getCategories';
export const GET_CATEGORY = 'getCategory';
export const ADD_CATEGORY = 'addCategory';
export const EDIT_CATEGORY = 'editCategory';
export const DELETE_CATEGORY = 'deleteCategory';
export const CLEAR_CATEGORIES_ISLOADING = 'clearCategoriesIsLoading';

// subscription
export const GET_SUBSCRIPTIONS = 'getSubscriptions';
export const CHECK_IF_SUBSCRIPTION_PLAN_IS_PURCHASED = 'checkIfSubscriptionPlanIsPurchased';
export const GET_SUBSCRIPTION = 'getSubscription';
export const EDIT_SUBSCRIPTION = 'editSubscription';
export const DELETE_SUBSCRIPTION = 'deleteSubscription';
export const CLEAR_SUBSCRIPTIONS_ISLOADING = 'clearSubscriptionsIsLoading';
export const CLEAR_SUBSCRIPTIONS = 'clearSubscriptions';

// staff
export const GET_STAFFS = 'getStaffs';
export const GET_STAFF = 'getStaff';
export const ADD_STAFF = 'addStaff';
export const EDIT_STAFF = 'editStaff';
export const DELETE_STAFF = 'deleteStaff';
export const TOGGLE_STAFF_STATUS = 'toggleStaffStatus';
export const GET_STAFF_APPOINTMENTS = 'getStaffAppointments';
export const GET_STAFF_ACTIVITIES = 'getStaffActivities';
export const CLEAR_STAFFS_ISLOADING = 'clearStaffsIsLoading';
export const CLEAR_STAFFS = 'clearStaffs';

// payment
export const REQUEST_CHAPA_PAYMENT = 'requestChapaPayment';
export const CHECK_PAYMENT_CONFIRMATION = 'checkPaymentConfirmation';
export const CLEAR_PAYMENTS_ISLOADING = 'clearPaymentsIsLoading';

// stats
export const GET_DASHBOARD_STATS = 'getDashboardStats';
export const CLEAR_STATS_ISLOADING = 'clearStatsIsLoading';
export const CLEAR_STATS = 'clearStats';

// notification
export const GET_NOTIFICATION = 'getNotification';
export const CLEAR_NOTIFICATION_ISLOADING = 'clearNotificationIsLoading';

// otp_verification
export const REQUEST_OTP_CODE = 'requestOTPCode';
export const VERIFY_OTP_CODE = 'verifyOTPCode';
export const CHECK_OTP_EXPIRY = 'checkOTPExpiry';
export const CLEAR_OTP_VERIFICATION_ISLOADING = 'clearOTPVerificationLoading';

// company_info
export const GET_COMPANY_INFO = 'getCompanyInfo';
export const GET_MY_COMPANY_INFO = 'getMyCompanyInfo';
export const GET_MY_COMPANIES = 'getMyCompanies';
export const SWITCH_COMPANY = 'switchCompany';
export const EDIT_MY_COMPANY_INFO = 'editMyCompanyInfo';
export const CLEAR_COMPANY_INFO_ISLOADING = 'clearStatsIsLoading';

// error 
export const CLEAR_ERRORMSG = 'clearErrorMessage'
export const UPDATE_ERRORMSG = 'updateErrorMessage'