import baseAPI from '@/api/base';
import { ADD_SERVICE_URL, GET_SERVICES_PUBLIC_URL, GET_SERVICES_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { AddServiceDto, GetServiceDto, EditServiceDto } from '@/dtos/service.dto';
import { EDIT_SERVICE_URL } from '../utils/urls';

class Service {
    static getServices = async(getServiceDto: GetServiceDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_SERVICES_URL, {
                    params: {
                        ...getServiceDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static getServicesPublic = async(getServiceDto: GetServiceDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_SERVICES_PUBLIC_URL, {
                    params: {
                        ...getServiceDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static addService = async(addServiceDto: AddServiceDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_SERVICE_URL, {
                    ...addServiceDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static editService = async(editServiceDto: EditServiceDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_SERVICE_URL, {
                    ...editServiceDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }
}

export default Service