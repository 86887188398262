// account
export const SET_ACCOUNT = 'setAccount';
export const SET_SESSION_TOKEN = 'setToken';

// appointment
export const SET_APPOINTMENT = 'setAppointment';
export const SET_APPOINTMENTS = 'setAppointments';
export const EMPTY_APPOINTMENTS = 'emptyAppointments';
export const SET_CALENDAR_APPOINTMENTS = 'setCalendarAppointments';

// customer
export const SET_CUSTOMER = 'setCustomer';
export const SET_CUSTOMERS = 'setCustomers';
export const SET_CUSTOMER_APPOINTMENTS = 'setCustomerAppointments';
export const SET_CUSTOMER_APPOINTMENTS_ADMIN = 'setCustomerAppointmentsAdmin';
export const SET_CUSTOMER_SMS_ADMIN = 'setCustomerSMSAdmin';

// service
export const SET_SERVICE = 'setService';
export const SET_SERVICES = 'setServices';
export const SET_SERVICES_PUBLIC = 'setServicesPublic';

// category
export const SET_CATEGORY = 'setCategory';
export const SET_CATEGORIES = 'setCategories';

// subscription
export const SET_SUBSCRIPTION = 'setSubscription';
export const SET_SUBSCRIPTIONS = 'setSubscriptions';
export const SET_SUBSCRIPTION_PLAN_PURCHASE_CHECK = 'setSubscriptionPlanPurchaseCheck';

// staff
export const SET_STAFF = 'setStaff';
export const SET_STAFFS = 'setStaffs';
export const SET_STAFF_APPOINTMENTS = 'setStaffAppointments';
export const SET_STAFF_ACTIVITIES = 'setStaffActivities';

// payment
export const SET_CHAPA_PAYMENT_REQUEST_RESPONSE = 'setChapaPaymentRequestResponse';
export const SET_PAYMENT_CONFIRMATION = 'setPaymentConfirmation';

// notification
export const SET_NOTIFICATION = 'setNotification';

// stats
export const SET_DASHBOARD_STATS = 'setDashboardStats';

// company_info
export const SET_COMPANY_INFO = 'setmyCompanyInfo';
export const SET_MY_COMPANY_INFO = 'setMyCompanyInfo';
export const SET_MY_COMPANIES = 'setMyCompanies';

// otp_verification
export const SET_OTP_REQUEST = 'setOTPRequest';
export const SET_OTP_VERIFICATION = 'setOTPVerification';
export const SET_OTP_EXPIRY = 'setOTPExpiry';

// error
export const SET_ERRORMSG = 'setErrorMsg';