export const SIGNUP_ROUTE = "SIGNUP_ROUTE"
export const LOGIN_ROUTE = "LOGIN_ROUTE"
export const DASHBOARD_ROUTE = "DASHBOARD_ROUTE"
export const APPOINTMENTS_ROUTE = "APPOINTMENTS_ROUTE"
export const SERVICES_ROUTE = "SERVICES_ROUTE"
export const CUSTOMERS_ROUTE = "CUSTOMERS_ROUTE"
export const STAFF_ROUTE = "STAFF_ROUTE"
export const STAFF_PROFILE_ROUTE = "STAFF_PROFILE_ROUTE"
export const SUBSCRIPTIONS_ROUTE = "SUBSCRIPTIONS_ROUTE"
export const SETTINGS_ROUTE = "SETTINGS_ROUTE"
export const PROFILE_ROUTE = "PROFILE_ROUTE"
export const SUBSCRIPTION_OPTION_ROUTE = "SUBSCRIPTION_OPTION_ROUTE"
export const PAYMENT_CONFIRMATION_ROUTE = "PAYMENT_CONFIRMATION_ROUTE"

export const APPOINTMENT_REQUEST_ROUTE = "APPOINTMENT_REQUEST_ROUTE"
export const APPOINTMENT_REQUEST_SUCCESS_ROUTE = "APPOINTMENT_REQUEST_SUCCESS_ROUTE"

export const CUSTOMER_APPOINTMENT_REQUEST_ROUTE = "CUSTOMER_APPOINTMENT_REQUEST_ROUTE"
export const CUSTOMER_OTP_VERIFICATION_ROUTE = "CUSTOMER_OTP_VERIFICATION_ROUTE"
export const CUSTOMER_APPOINTMENT_HISTORY_ROUTE = "CUSTOMER_APPOINTMENT_HISTORY_ROUTE"
