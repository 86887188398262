import store from "@/store"
import { CLEAR_ACCOUNT_ISLOADING, CLEAR_APPOINTMENTS_ISLOADING, CLEAR_CATEGORIES_ISLOADING, CLEAR_CUSTOMERS_ISLOADING, CLEAR_SERVICES_ISLOADING, CLEAR_STAFFS_ISLOADING, CLEAR_STATS_ISLOADING, CLEAR_SUBSCRIPTIONS_ISLOADING, CLEAR_OTP_VERIFICATION_ISLOADING, CLEAR_PAYMENTS_ISLOADING } from "@/store/action_types"

export const resetLoaders = () => {
    store.dispatch(CLEAR_ACCOUNT_ISLOADING)
    store.dispatch(CLEAR_APPOINTMENTS_ISLOADING)
    store.dispatch(CLEAR_CATEGORIES_ISLOADING)
    store.dispatch(CLEAR_CUSTOMERS_ISLOADING)
    store.dispatch(CLEAR_OTP_VERIFICATION_ISLOADING)
    store.dispatch(CLEAR_PAYMENTS_ISLOADING)
    store.dispatch(CLEAR_SERVICES_ISLOADING)
    store.dispatch(CLEAR_SUBSCRIPTIONS_ISLOADING)
    store.dispatch(CLEAR_STAFFS_ISLOADING)
    store.dispatch(CLEAR_STATS_ISLOADING)
}