import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_DASHBOARD_STATS, SET_ERRORMSG } from '../mutation_types'
import {
    GET_DASHBOARD_STATS,
    CLEAR_STATS_ISLOADING,
    CLEAR_STATS,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import Stats from '@/services/stats'
import asyncHandler from '@/utils/async_handler'

const stats: Module<any, {}> = {
    state: {
        dashboardStats: {
            data: null,
            isLoading: false
        },
    },
    getters: {
        dashboardStats: (state: { dashboardStats: any }) => state.dashboardStats
    },
    mutations: {
        [SET_DASHBOARD_STATS]: (state: { dashboardStats: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.dashboardStats.data = payload.data : null, state.dashboardStats.isLoading = payload.isLoading },
    },
    actions: {
        [GET_DASHBOARD_STATS]: async({ commit }: any, payload: any) => {
            commit(SET_DASHBOARD_STATS, { data: null, isLoading: true })

            let result: AxiosResponse = await Stats.getDashboardStats()
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_DASHBOARD_STATS, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_DASHBOARD_STATS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_DASHBOARD_STATS, { isLoading: false })
            }
            return []
        },
        [CLEAR_STATS_ISLOADING]: ({ commit }) => {
            commit(SET_DASHBOARD_STATS, { isLoading: false })
        },
        [CLEAR_STATS]: ({ commit }) => {
            commit(SET_DASHBOARD_STATS, { data: null, isLoading: false })
        }
    }
}

export default stats