import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_OTP_REQUEST, SET_OTP_VERIFICATION, SET_ERRORMSG, SET_OTP_EXPIRY } from '../mutation_types'
import {
    REQUEST_OTP_CODE,
    VERIFY_OTP_CODE,
    CLEAR_OTP_VERIFICATION_ISLOADING,
    CHECK_OTP_EXPIRY,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import OTPVerification from '@/services/otp_verification'
import asyncHandler from '@/utils/async_handler'
import { RequestOTPCode, VerifyOTP } from '@/dtos/otp_verification.dto'

const otpVerification: Module<any, {}> = {
    state: {
        otpRequest: {
            data: null,
            isLoading: false
        },
        otpVerification: {
            data: null,
            isLoading: false
        },
        otpExpiry: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        otpRequest: (state: { otpRequest: any }) => state.otpRequest,
        otpVerification: (state: { otpVerification: any }) => state.otpVerification,
        otpExpiry: (state: { otpExpiry: any }) => state.otpExpiry
    },
    mutations: {
        [SET_OTP_REQUEST]: (state: { otpRequest: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.otpRequest.data = payload.data : null, state.otpRequest.isLoading = payload.isLoading },
        [SET_OTP_VERIFICATION]: (state: { otpVerification: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.otpVerification.data = payload.data : null, state.otpVerification.isLoading = payload.isLoading },
        [SET_OTP_EXPIRY]: (state: { otpExpiry: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.otpExpiry.data = payload.data : null, state.otpExpiry.isLoading = payload.isLoading }
    },
    actions: {
        [REQUEST_OTP_CODE]: async({ commit }: any, payload: any) => {
            commit(SET_OTP_REQUEST, { data: null, isLoading: true })

            let result: AxiosResponse = await OTPVerification.requestOTPCode(payload as RequestOTPCode)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_OTP_REQUEST, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_OTP_REQUEST, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_OTP_REQUEST, { isLoading: false })
            }
            return null
        },
        [VERIFY_OTP_CODE]: async({ commit }: any, payload: any) => {
            commit(SET_OTP_VERIFICATION, { data: null, isLoading: true })

            let result: AxiosResponse = await OTPVerification.verifyOTPCode(payload as VerifyOTP)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_OTP_VERIFICATION, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_OTP_VERIFICATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_OTP_VERIFICATION, { isLoading: false })
            }
            return null
        },
        [CHECK_OTP_EXPIRY]: async({ commit }: any, payload: any) => {
            commit(SET_OTP_EXPIRY, { data: null, isLoading: true })

            let result: AxiosResponse = await OTPVerification.checkOTPExpiry(payload as VerifyOTP)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_OTP_EXPIRY, { data: result.data.data, isLoading: false })
                    return true
                }
                commit(SET_OTP_EXPIRY, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_OTP_EXPIRY, { isLoading: false })
            }
            return false
        },
        [CLEAR_OTP_VERIFICATION_ISLOADING]: ({ commit }) => {
            commit(SET_OTP_REQUEST, { isLoading: false })
            commit(SET_OTP_VERIFICATION, { isLoading: false })
            commit(SET_OTP_EXPIRY, { isLoading: false })
        }
    }
}

export default otpVerification