import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_CUSTOMER, SET_CUSTOMERS, SET_CUSTOMER_APPOINTMENTS, SET_CUSTOMER_APPOINTMENTS_ADMIN, SET_CUSTOMER_SMS_ADMIN, SET_ERRORMSG } from '../mutation_types'
import {
    GET_CUSTOMER,
    GET_CUSTOMERS,
    ADD_CUSTOMER,
    EDIT_CUSTOMER,
    DELETE_CUSTOMER,
    GET_CUSTOMER_APPOINTMENTS_ADMIN,
    GET_CUSTOMER_SMS_ADMIN,
    CLEAR_CUSTOMERS_ISLOADING,
    GET_CUSTOMER_APPOINTMENTS,
    CLEAR_CUSTOMERS,
    CLEAR_CUSTOMER,
    CLEAR_CUSTOMERS_METADATA,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import Customer from '@/services/customer'
import { AddCustomerDto, EditCustomerDto, GetCustomerDto } from '@/dtos/customer.dto'
import { GetAppointmentDto } from '@/dtos/appointment.dto'
import { GetSMSDto } from '@/dtos/sms.dto'

const customer: Module<any, {}> = {
    state: {
        customer: {
            data: null,
            isLoading: false
        },
        customers: {
            data: null,
            metaData: null,
            isLoading: false
        },
        customerAppointments: {
            data: null,
            isLoading: false
        },
        customerAppointmentsAdmin: {
            data: null,
            isLoading: false
        },
        customerSMS: {
            data: null,
            isLoading: false
        },
        customerSMSAdmin: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        customer: (state: { customer: any }) => state.customer,
        customers: (state: { customers: any }) => state.customers,
        customerAppointments: (state: { customerAppointments: any }) => state.customerAppointments,
        customerAppointmentsAdmin: (state: { customerAppointmentsAdmin: any }) => state.customerAppointmentsAdmin,
        customerSMSAdmin: (state: { customerSMSAdmin: any }) => state.customerSMSAdmin
    },
    mutations: {
        [SET_CUSTOMER]: (state: { customer: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.customer.data = payload.data : null, state.customer.isLoading = payload.isLoading },
        [SET_CUSTOMERS]: (state: { customers: StateObject }, payload: { data: null; metaData: null, isLoading: any }) => { payload.data != null ? state.customers.data = payload.data : null, state.customers.isLoading = payload.isLoading, payload.metaData != null ? state.customers.metaData = payload.metaData : state.customers.metaData = null },
        [SET_CUSTOMER_APPOINTMENTS]: (state: { customerAppointments: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.customerAppointments.data = payload.data : null, state.customerAppointments.isLoading = payload.isLoading },
        [SET_CUSTOMER_APPOINTMENTS_ADMIN]: (state: { customerAppointmentsAdmin: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.customerAppointmentsAdmin.data = payload.data : null, state.customerAppointmentsAdmin.isLoading = payload.isLoading },
        [SET_CUSTOMER_SMS_ADMIN]: (state: { customerSMSAdmin: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.customerSMSAdmin.data = payload.data : null, state.customerSMSAdmin.isLoading = payload.isLoading }
    },
    actions: {
        [GET_CUSTOMERS]: async({ commit }: any, payload: any) => {
            commit(SET_CUSTOMERS, { metaData: null, isLoading: true })

            let result: AxiosResponse = await Customer.getCustomers(payload)
            console.log('result custt', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_CUSTOMERS, { data: result.data.data, isLoading: false, metaData: result.data.metaData })
                    return result.data.data
                }
                commit(SET_CUSTOMERS, { data: null, metaData: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CUSTOMERS, { isLoading: false })
            }
            return null
        },
        [GET_CUSTOMER]: async({ commit }: any, payload: any) => {
            commit(SET_CUSTOMER, { data: null, isLoading: true })

            let result: AxiosResponse = await Customer.getCustomers(payload as GetCustomerDto)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_CUSTOMER, { data: result.data.data[0], isLoading: false })
                    return
                }
                commit(SET_CUSTOMER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CUSTOMER, { isLoading: false })
            }
        },
        [GET_CUSTOMER_APPOINTMENTS_ADMIN]: async({ commit }: any, payload: any) => {
            commit(SET_CUSTOMER_APPOINTMENTS_ADMIN, { data: null, isLoading: true })

            let result: AxiosResponse = await Customer.getCustomerAppointmentsAdmin(payload as GetAppointmentDto)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_CUSTOMER_APPOINTMENTS_ADMIN, { data: result.data.data, isLoading: false })
                    return
                }
                commit(SET_CUSTOMER_APPOINTMENTS_ADMIN, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CUSTOMER_APPOINTMENTS_ADMIN, { isLoading: false })
            }
        },
        [GET_CUSTOMER_SMS_ADMIN]: async({ commit }: any, payload: any) => {
            commit(SET_CUSTOMER_SMS_ADMIN, { data: null, isLoading: true })

            let result: AxiosResponse = await Customer.getCustomerSMSAdmin(payload as GetSMSDto)
            console.log('result sms', result)
            console.log('payload sms', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_CUSTOMER_SMS_ADMIN, { data: result.data.data, isLoading: false })
                    return
                }
                commit(SET_CUSTOMER_SMS_ADMIN, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CUSTOMER_SMS_ADMIN, { isLoading: false })
            }
        },
        [GET_CUSTOMER_APPOINTMENTS]: async({ commit }: any, payload: any) => {
            commit(SET_CUSTOMER_APPOINTMENTS, { data: null, isLoading: true })

            let result: AxiosResponse = await Customer.getCustomerAppointments(payload as GetAppointmentDto)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_CUSTOMER_APPOINTMENTS, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_CUSTOMER_APPOINTMENTS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CUSTOMER_APPOINTMENTS, { isLoading: false })
            }
            return []
        },
        [ADD_CUSTOMER]: async({ commit }: any, payload: any) => {
            commit(SET_CUSTOMER, { data: null, isLoading: true })

            let result: AxiosResponse = await Customer.addCustomer(payload as AddCustomerDto)

            if(result) {
                if (result.data.status) {
                    commit(SET_CUSTOMER, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_CUSTOMER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CUSTOMER, { isLoading: false })
            }
            commit(SET_CUSTOMER, { isLoading: false })
            return null
        },
        [EDIT_CUSTOMER]: async({ commit }: any, payload: any) => {
            commit(SET_CUSTOMER, { data: null, isLoading: true })

            let result: AxiosResponse = await Customer.editCustomer(payload as EditCustomerDto)

            if(result) {
                if (result.data.status) {
                    commit(SET_CUSTOMER, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_CUSTOMER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CUSTOMER, { isLoading: false })
            }
            commit(SET_CUSTOMER, { isLoading: false })
            return null
        },
        [CLEAR_CUSTOMERS_METADATA]: ({ commit }) => {
            commit(SET_CUSTOMERS, { metaData: null })
        },
        [CLEAR_CUSTOMERS_ISLOADING]: ({ commit }) => {
            commit(SET_CUSTOMER, { isLoading: false })
            commit(SET_CUSTOMERS, { isLoading: false })
            commit(SET_CUSTOMER_APPOINTMENTS_ADMIN, { isLoading: false })
        },
        [CLEAR_CUSTOMER]: ({ commit }) => {
            commit(SET_CUSTOMER, { data: null, isLoading: false })
            commit(SET_CUSTOMER_APPOINTMENTS_ADMIN, { data: null, isLoading: false })
            commit(SET_CUSTOMER_SMS_ADMIN, { data: null, isLoading: false })
        },
        [CLEAR_CUSTOMERS]: ({ commit }) => {
            commit(SET_CUSTOMER, { data: null, isLoading: false })
            commit(SET_CUSTOMERS, { data: null, isLoading: false })
            commit(SET_CUSTOMER_APPOINTMENTS, { data: null, isLoading: false })
            commit(SET_CUSTOMER_APPOINTMENTS_ADMIN, { data: null, isLoading: false })
            commit(SET_CUSTOMER_SMS_ADMIN, { data: null, isLoading: false })
        }
    }
}

export default customer