import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_CHAPA_PAYMENT_REQUEST_RESPONSE, SET_ERRORMSG, SET_PAYMENT_CONFIRMATION } from '../mutation_types'
import {
    REQUEST_CHAPA_PAYMENT,
    CHECK_PAYMENT_CONFIRMATION,
    CLEAR_PAYMENTS_ISLOADING,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import Payment from '@/services/payment'
import { ChapaPaymentRequestDto } from '@/dtos/payment.dto'

const payment: Module<any, {}> = {
    state: {
        chapaPaymentRequestResponse: {
            data: null,
            isLoading: false
        },
        paymentConfirmation: {
            data: null,
            isLoading: false
        },
    },
    getters: {
        chapaPaymentRequestResponse: (state: { chapaPaymentRequestResponse: any }) => state.chapaPaymentRequestResponse,
        paymentConfirmation: (state: { paymentConfirmation: any }) => state.paymentConfirmation
    },
    mutations: {
        [SET_CHAPA_PAYMENT_REQUEST_RESPONSE]: (state: { chapaPaymentRequestResponse: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.chapaPaymentRequestResponse.data = payload.data : null, state.chapaPaymentRequestResponse.isLoading = payload.isLoading },
        [SET_PAYMENT_CONFIRMATION]: (state: { paymentConfirmation: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.paymentConfirmation.data = payload.data : null, state.paymentConfirmation.isLoading = payload.isLoading }
    },
    actions: {
        [REQUEST_CHAPA_PAYMENT]: async({ commit }: any, payload: any) => {
            commit(SET_CHAPA_PAYMENT_REQUEST_RESPONSE, { data: null, isLoading: true })

            let result: AxiosResponse = await Payment.requestChapaPayment(payload as ChapaPaymentRequestDto)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_CHAPA_PAYMENT_REQUEST_RESPONSE, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_CHAPA_PAYMENT_REQUEST_RESPONSE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CHAPA_PAYMENT_REQUEST_RESPONSE, { isLoading: false })
            }
            return null
        },
        [CHECK_PAYMENT_CONFIRMATION]: async({ commit }: any, payload: any) => {
            commit(SET_PAYMENT_CONFIRMATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Payment.checkPaymentConfirmation(payload as ChapaPaymentRequestDto)
            console.log('CHECK_PAYMENT_CONFIRMATIONCHECK_PAYMENT_CONFIRMATION', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_PAYMENT_CONFIRMATION, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_PAYMENT_CONFIRMATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_PAYMENT_CONFIRMATION, { isLoading: false })
            }
            return null
        },
        [CLEAR_PAYMENTS_ISLOADING]: ({ commit }) => {
            commit(SET_CHAPA_PAYMENT_REQUEST_RESPONSE, { isLoading: false })
            commit(SET_PAYMENT_CONFIRMATION, { isLoading: false })
        }
    }
}

export default payment