import baseAPI from '@/api/base';
import { ChapaPaymentRequestDto, PaymentRequestDto } from '@/dtos/payment.dto';
import { CHECK_PAYMENT_CONFIRMATON_URL, REQUEST_CHAPA_PAYMENT_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Payment {
    static requestChapaPayment = async(chapaPaymentRequestDto: ChapaPaymentRequestDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(REQUEST_CHAPA_PAYMENT_URL, {
                    ...chapaPaymentRequestDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static checkPaymentConfirmation = async(paymentRequestDto: PaymentRequestDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(CHECK_PAYMENT_CONFIRMATON_URL, {
                    params: {
                        ...paymentRequestDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }
}

export default Payment