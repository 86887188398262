import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import multiguard from 'vue-router-multiguard';
import DashboardView from "../views/Dashboard.vue";
import {
  CUSTOMERS_ROUTE,
  LOGIN_ROUTE,
  SERVICES_ROUTE,
  SIGNUP_ROUTE,
  APPOINTMENTS_ROUTE,
  SETTINGS_ROUTE,
  SUBSCRIPTIONS_ROUTE,
  DASHBOARD_ROUTE,
  STAFF_ROUTE,
  APPOINTMENT_REQUEST_ROUTE,
  APPOINTMENT_REQUEST_SUCCESS_ROUTE,
  PROFILE_ROUTE,
  CUSTOMER_APPOINTMENT_HISTORY_ROUTE,
  CUSTOMER_APPOINTMENT_REQUEST_ROUTE,
  CUSTOMER_OTP_VERIFICATION_ROUTE,
  STAFF_PROFILE_ROUTE,
  SUBSCRIPTION_OPTION_ROUTE,
  PAYMENT_CONFIRMATION_ROUTE
} from '../utils/routes'
import { ifAuthDeny } from "./guards/auth";
import { ifNotAuthDeny } from "./guards/not_auth";
import { adminRoleGuard } from "./guards/role";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: DASHBOARD_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: DashboardView,
  },
  {
    path: "/auth/signup",
    name: SIGNUP_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () =>
      import("../views/Auth/Signup.vue"),
  },
  {
    path: "/auth/login",
    name: LOGIN_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () =>
      import("../views/Auth/Login.vue"),
  },
  {
    path: "/customers",
    name: CUSTOMERS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () =>
      import("../views/Customers.vue"),
  },
  {
    path: "/appointments",
    name: APPOINTMENTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () =>
      import("../views/Appointments.vue"),
  },
  {
    path: "/staff",
    name: STAFF_ROUTE,
    beforeEnter: multiguard([ ifNotAuthDeny, adminRoleGuard ]),
    component: () =>
      import("../views/Staff/Staff.vue"),
  },
  {
    path: "/staff/profile/:id",
    name: STAFF_PROFILE_ROUTE,
    beforeEnter: multiguard([ ifNotAuthDeny, adminRoleGuard ]),
    component: () =>
      import("../views/Staff/Staff_Profile.vue"),
  },
  {
    path: "/services",
    name: SERVICES_ROUTE,
    beforeEnter: multiguard([ ifNotAuthDeny, adminRoleGuard ]),
    component: () =>
      import("../views/Services.vue"),
  },
  {
    path: "/subscriptions",
    name: SUBSCRIPTIONS_ROUTE,
    beforeEnter: multiguard([ ifNotAuthDeny, adminRoleGuard ]),
    component: () =>
      import("../views/Subscriptions.vue"),
  },
  {
    path: "/settings",
    name: SETTINGS_ROUTE,
    beforeEnter: multiguard([ ifNotAuthDeny, adminRoleGuard ]),
    component: () =>
      import("../views/Settings.vue"),
  },
  {
    path: "/profile",
    name: PROFILE_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () =>
      import("../views/Profile.vue"),
  },
  {
    path: "/appointment-request/:id",
    name: APPOINTMENT_REQUEST_ROUTE,
    component: () =>
      import("../views/Appointment_Request.vue"),
  },
  {
    path: "/subscription-options/:account/:company",
    name: SUBSCRIPTION_OPTION_ROUTE,
    component: () =>
      import("../views/Subscription_Options.vue"),
  },
  {
    path: "/payment-confirmation/:payment/:company/:subscriptionPlan",
    name: PAYMENT_CONFIRMATION_ROUTE,
    component: () =>
      import("../views/Payment_Confirmation.vue"),
  },
  {
    path: "/appointment-request-success",
    name: APPOINTMENT_REQUEST_SUCCESS_ROUTE,
    component: () =>
      import("../views/Appointment_Request_Success.vue"),
  },
  {
    path: "/customer-appointment-request",
    name: CUSTOMER_APPOINTMENT_REQUEST_ROUTE,
    component: () =>
      import("../views/Customer_Appointments_Request.vue"),
  },
  {
    path: "/customer-otp-verification/:number",
    name: CUSTOMER_OTP_VERIFICATION_ROUTE,
    component: () =>
      import("../views/Customer_OTP_Verification.vue"),
  },
  {
    path: "/customer-appointments/:number/:customerId/:verificationId",
    name: CUSTOMER_APPOINTMENT_HISTORY_ROUTE,
    component: () =>
      import("../views/Customer_Appointments.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
