import { RouteLocationNormalized } from 'vue-router';
import store from '@/store';
import { ACCOUNT_ROLES } from '@/utils/const';

export const adminRoleGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
    const currentUser = store.getters.account.data

    console.log('currentUser.roles', currentUser)

    // check if account role does not include admin
    let filter: string[] = []
    filter = [ACCOUNT_ROLES.admin, ACCOUNT_ROLES.root, ACCOUNT_ROLES.superAdmin].filter(value => currentUser.roles.includes(value))
    if (filter.length == 0) {
        // route back to from path
        return next({ path: from.path });
    }

    next();
}

export const coordinatorRoleGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
    const currentUser = store.getters.account.data

    // check if account role does not include admin
    if (!currentUser.roles.includes(ACCOUNT_ROLES.coordinator)) {
        // route back to from path
        return next({ path: from.path });
    }

    next();
}