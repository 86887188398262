import baseAPI from '@/api/base';
import { ADD_CUSTOMER_URL, GET_APPOINTMENTS_URL, GET_CUSTOMERS_URL, GET_CUSTOMER_APPOINTMENTS_URL, GET_SMS_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { AddCustomerDto, EditCustomerDto, GetCustomerDto } from '@/dtos/customer.dto';
import { GetAppointmentDto } from '@/dtos/appointment.dto';
import { GetSMSDto } from '@/dtos/sms.dto';
import { EDIT_CUSTOMER_URL } from '../utils/urls';

class Customer {
    static getCustomers = async(getCustomerDto: GetCustomerDto) : Promise<AxiosResponse> => {
        console.log('getCustomerDtogetCustomerDtogetCustomerDto', getCustomerDto)
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_CUSTOMERS_URL, {
                    params: {
                        ...getCustomerDto,
                        sort: 'slug'
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static getCustomerAppointmentsAdmin = async(getAppointmentDto: GetAppointmentDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_APPOINTMENTS_URL, {
                    params: {
                        ...getAppointmentDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static getCustomerSMSAdmin = async(getSMSDto: GetSMSDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_SMS_URL, {
                    params: {
                        ...getSMSDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static addCustomer = async(addCustomerDto: AddCustomerDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_CUSTOMER_URL, {
                    ...addCustomerDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static editCustomer = async(editCustomerDto: EditCustomerDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_CUSTOMER_URL, {
                    ...editCustomerDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static getCustomerAppointments = async(getAppointmentDto: GetAppointmentDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_CUSTOMER_APPOINTMENTS_URL, {
                    params: {
                        ...getAppointmentDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }
}

export default Customer