import baseAPI from '@/api/base';
import { ADD_APPOINTMENT_URL, DELETE_APPOINTMENT_URL, EDIT_APPOINTMENT_STATUS_URL, GET_APPOINTMENTS_URL, REQUEST_APPOINTMENT_URL, GET_CALENDAR_APPOINTMENTS_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { AddAppointmentDto, EditAppointmentDto, GetAppointmentDto, RequestAppointmentDto } from '@/dtos/appointment.dto';

class Appointment {
    static getAppointments = async(getAppointmentDto: GetAppointmentDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_APPOINTMENTS_URL, {
                    params: {
                        ...getAppointmentDto,
                        sort: '-appointmentDateTime'
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static getCalendarAppointments = async(getAppointmentDto: GetAppointmentDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_CALENDAR_APPOINTMENTS_URL, {
                    params: {
                        ...getAppointmentDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static addAppointment = async(addAppointmentDto: AddAppointmentDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_APPOINTMENT_URL, {
                    ...addAppointmentDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static requestAppointment = async(requestAppointmentDto: RequestAppointmentDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(REQUEST_APPOINTMENT_URL, {
                    ...requestAppointmentDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static changeAppointmentStatus = async(editAppointmentDto: EditAppointmentDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_APPOINTMENT_STATUS_URL, {
                    ...editAppointmentDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static deleteAppointment = async(getAppointmentDto: GetAppointmentDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_APPOINTMENT_URL}/${getAppointmentDto._id}`)
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }
}

export default Appointment