<template>
  <div id="app" v-if="(route != null ? exceptionScreens.includes(route) : false) ? true : (windowWidth >= LARGE_SCREEN_SIZE) ? true : false">
    <router-view/>
    <vue-final-modal v-if="showNotificationModal && notification != null" classes="modal-container" content-class="modal-content">
        <button class="modal__close" @click="showConfirmModal = false">
          <mdi-close></mdi-close>
        </button>
        <span class="modal__title">Notification</span>
        <div class="text-center">
            <img class="svg-img" src="./assets/images/checkmark.svg" alt="" srcset="">
        </div>
        <div class="modal__content text-center"><br>{{ notification.message }}<br><br></div>
        <div class="modal__action">
            <button @click="showNotificationModal = false" class="btn btn-light col-3 offset-9">Close</button>
        </div>
    </vue-final-modal>
  </div>
  <div v-else class="container">
    <br>
    <div class="alert alert-primary" role="alert">
      <h6 class="text-center">Content can not be displayed on small screens. Please use a larger screen.</h6>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useRoute, useRouter } from 'vue-router'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import { LARGE_SCREEN_SIZE } from "@/utils/const";
import { APPOINTMENT_REQUEST_ROUTE, APPOINTMENT_REQUEST_SUCCESS_ROUTE, CUSTOMER_APPOINTMENT_REQUEST_ROUTE, CUSTOMER_OTP_VERIFICATION_ROUTE, CUSTOMER_APPOINTMENT_HISTORY_ROUTE, SUBSCRIPTION_OPTION_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE } from "@/utils/routes";
import { GET_NOTIFICATION } from './store/action_types';

export default {
  components: {
    VueFinalModal,
    ModalsContainer
  },
  data() {
    return{
      APPOINTMENT_REQUEST_ROUTE,
      LARGE_SCREEN_SIZE,
      windowWidth: 0,
      showNotificationModal: false,
      exceptionScreens: [
        APPOINTMENT_REQUEST_ROUTE,
        APPOINTMENT_REQUEST_SUCCESS_ROUTE,
        CUSTOMER_APPOINTMENT_REQUEST_ROUTE,
        CUSTOMER_OTP_VERIFICATION_ROUTE,
        CUSTOMER_APPOINTMENT_HISTORY_ROUTE,
        SUBSCRIPTION_OPTION_ROUTE,
        LOGIN_ROUTE,
        SIGNUP_ROUTE
      ]
    } 
  },
  computed: {
    route() {
        return this.$route.name?.toString()
    },
    notificationIsLoading() {
        return this.$store.getters.notification.isLoading
    },
    notification() {
        return this.$store.getters.notification.data
    }
  },
  methods:{
    getScreenSize() {
      this.windowWidth = window.innerWidth;
    },
    async checkNotifications() {
      const route = useRoute()
      const router = useRouter()
      await router.isReady()
      if(!this.exceptionScreens.includes(route.name)) {
        this.$store.dispatch(GET_NOTIFICATION).then((result) => {
          if(result) {
            this.showNotificationModal = true
          }
        })
      }
    }
  },
  created() {
    window.addEventListener('resize', () => this.getScreenSize())
    this.getScreenSize()
    this.checkNotifications()
  },
  unmounted() {
    this.showNotificationModal = false
  }
}
</script>
<style scoped>
@import './assets/style/main.css';

::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  position: relative;
  left: 35%;
  margin-top: 50px;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  font-size: 18px;
  font-weight: 600;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
  font-size: 15px!important;
}

.btn-close {
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  width: 75%;

}

.svg-img {
  height: 40px;
  align-self: center;
  margin-right: 5px;
  filter: invert(21%) sepia(94%) saturate(2017%) hue-rotate(207deg) brightness(105%) contrast(104%);
}
</style>
