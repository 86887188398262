import baseAPI from '@/api/base';
import { GET_DASHBOARD_STATS_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Stats {
    static getDashboardStats = async() : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_DASHBOARD_STATS_URL)
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }
}

export default Stats