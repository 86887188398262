import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_SERVICE, SET_SERVICES, SET_ERRORMSG, SET_SERVICES_PUBLIC } from '../mutation_types'
import {
    GET_SERVICE,
    GET_SERVICES,
    GET_SERVICES_PUBLIC,
    ADD_SERVICE,
    EDIT_SERVICE,
    DELETE_SERVICE,
    CLEAR_SERVICES_ISLOADING,
    CLEAR_SERVICES,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import Service from '@/services/service'
import { GetServiceDto, AddServiceDto, EditServiceDto } from '@/dtos/service.dto'

const service: Module<any, {}> = {
    state: {
        service: {
            data: null,
            isLoading: false
        },
        services: {
            data: null,
            isLoading: false
        },
        servicesPublic: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        service: (state: { service: any }) => state.service,
        services: (state: { services: any }) => state.services,
        servicesPublic: (state: { servicesPublic: any }) => state.servicesPublic
    },
    mutations: {
        [SET_SERVICE]: (state: { service: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.service.data = payload.data : null, state.service.isLoading = payload.isLoading },
        [SET_SERVICES]: (state: { services: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.services.data = payload.data : null, state.services.isLoading = payload.isLoading },
        [SET_SERVICES_PUBLIC]: (state: { servicesPublic: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.servicesPublic.data = payload.data : null, state.servicesPublic.isLoading = payload.isLoading },
    },
    actions: {
        [GET_SERVICES]: async({ commit }: any, payload: GetServiceDto) => {
            commit(SET_SERVICES, { data: null, isLoading: true })

            let result: AxiosResponse = await Service.getServices(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_SERVICES, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_SERVICES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_SERVICES, { isLoading: false })
            }
            return []
        },
        [GET_SERVICES_PUBLIC]: async({ commit }: any, payload: GetServiceDto) => {
            commit(SET_SERVICES_PUBLIC, { data: null, isLoading: true })

            let result: AxiosResponse = await Service.getServicesPublic(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_SERVICES_PUBLIC, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_SERVICES_PUBLIC, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_SERVICES_PUBLIC, { isLoading: false })
            }
            return []
        },
        [GET_SERVICE]: async({ commit }: any, payload: GetServiceDto) => {
            commit(SET_SERVICE, { data: null, isLoading: true })

            let result: AxiosResponse = await Service.getServices(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_SERVICE, { data: result.data.data[0], isLoading: false })
                    return result.data.data[0]
                }
                commit(SET_SERVICE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_SERVICE, { isLoading: false })
            }
            return null
        },
        [ADD_SERVICE]: async({ commit }: any, payload: AddServiceDto) => {
            commit(SET_SERVICE, { data: null, isLoading: true })

            let result: AxiosResponse = await Service.addService(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_SERVICE, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_SERVICE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_SERVICE, { isLoading: false })
            }
            return null
        },
        [EDIT_SERVICE]: async({ commit }: any, payload: any) => {
            commit(SET_SERVICE, { data: null, isLoading: true })

            let result: AxiosResponse = await Service.editService(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_SERVICE, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_SERVICE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_SERVICE, { isLoading: false })
            }
            return null
        },
        // [LOGOUT]: asyncHandler(async({ commit }) => {
        //     commit(SET_SERVICE, { data: null, isLoading: true })
        //         // const deviceInfo = await getDeviceInfo()
        //     const result = await axios.post(LOGOUT_URL, {
        //         // deviceInfo: deviceInfo
        //     })

        //     if (result.data.status) {
        //         commit(SET_SERVICE, { data: null, isLoading: false })
        //         commit(SET_SESSION_TOKEN, null)
        //         router.push({ name: LOGIN_ROUTE })
        //         return
        //     }
        //     commit(SET_SERVICE, { data: null, isLoading: false })
        //     commit(SET_ERRORMSG, result.data.msg)
        // }),
        // [LOGOUT_LOCALLY]: asyncHandler(async({ commit }) => {
        //     commit(SET_SERVICE, { data: null, isLoading: true })
        //     commit(SET_SESSION_TOKEN, null)
        //     router.push({ name: LOGIN_ROUTE })
        //     commit(SET_SERVICE, { data: null, isLoading: false })
        // }),
        // [UPDATE_SERVICE]: asyncHandler(async({ commit }) => {
        //     const result = await axios.get(UPDATE_AUTH_SERVICE)

        //     if (result.data.status) {
        //         commit(SET_SERVICE, { data: result.data.data })
        //         return
        //     }
        // }),
        [CLEAR_SERVICES_ISLOADING]: ({ commit }) => {
            commit(SET_SERVICE, { isLoading: false })
            commit(SET_SERVICES, { isLoading: false })
        },
        [CLEAR_SERVICES]: ({ commit }) => {
            commit(SET_SERVICE, { data: null, isLoading: false })
            commit(SET_SERVICES, { data: null, isLoading: false })
        }
    }
}

export default service