import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import filters from './filters/index'
import titleMixin from './mixin/titleMixin'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import 'primeicons/primeicons.css';
import Clipboard from 'v-clipboard'
import 'maz-ui/css/main.css'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'
import infiniteScroll from 'vue-infinite-scroll'
// const app = createApp({});
// app.use(ToastPlugin);
// app.use(store)
// app.use(router)
// app.mount('#app');

const app = createApp(App)

app.component('MazPhoneNumberInput', MazPhoneNumberInput)
app.mixin(titleMixin)

app.use(store).use(router).use(Vue3Tour).use(infiniteScroll).use(Clipboard).use(ToastPlugin).mount("#app");
app.config.globalProperties.$filters = filters
