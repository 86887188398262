import baseAPI from '@/api/base';
import { CHECK_SUBSCRIPTION_PLAN_PURCHASED_URL, GET_SUBSCRIPTIONS_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { GetSubscriptionDto } from '@/dtos/subscription.dto';

class Subscription {
    static getSubscriptions = async(getSubscriptionDto: GetSubscriptionDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_SUBSCRIPTIONS_URL, {
                    params: {
                        ...getSubscriptionDto,
                        sort: '-createdAt'
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static checkIfSubscriptionPlanIsPurchased = async(getSubscriptionDto: GetSubscriptionDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(CHECK_SUBSCRIPTION_PLAN_PURCHASED_URL, {
                    params: {
                        ...getSubscriptionDto
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }
}

export default Subscription