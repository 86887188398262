import baseAPI from '@/api/base';
import { GET_DASHBOARD_STATS_URL, GET_UNREAD_NOTIFICATON_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Notification {
    static getUnreadNotification = async() : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_UNREAD_NOTIFICATON_URL)

                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }
}

export default Notification