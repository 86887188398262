<template>
    <div class="sidenav">
        <div class="sidenav-brand">
            <br>
            <div>
                <h4 class="text-primary title"><strong>Ketero</strong></h4>
                <span class="text-secondary sub-title">Appointment Management</span>
                <br>
                <br>
                <h6 v-if="myCompaniesList.length == 0" class="text-secondary">{{ account.sessionCompany.name }}</h6>
                <div v-else class="dropdown" style="float:left;">
                    <button class="dropdown-btn"><h6 class="text-secondary">{{ account.sessionCompany.name }}<i class="pi pi-chevron-down"></i></h6></button>
                    <div class="dropdown-content" style="left:0;">
                        <template v-bind:key="index" v-for="(company, index) in myCompaniesList">
                            <a v-on:click="switchCompany(company._id)">{{ company.name }}</a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <br v-if="myCompaniesList.length > 0">
        <hr>
        <div class="menu">
            <ul class="nav-items-group">
                <router-link data-v-step="1" :to="{ name: DASHBOARD_ROUTE }"><li :class="[ [DASHBOARD_ROUTE].includes(route) ? 'selected' : null, 'nav-items' ]"><i class="pi pi-calendar-plus"></i> Dashboard</li></router-link>
                <router-link data-v-step="2" :to="{ name: APPOINTMENTS_ROUTE }"><li :class="[ [APPOINTMENTS_ROUTE].includes(route) ? 'selected' : null, 'nav-items' ]"><i class="pi pi-check-square"></i> Appointments</li></router-link>
                <router-link data-v-step="3" v-if="isAdmin" :to="{ name: SERVICES_ROUTE }"><li :class="[ [SERVICES_ROUTE].includes(route) ? 'selected' : null, 'nav-items' ]"><i class="pi pi-qrcode"></i> Services</li></router-link>
                <router-link :to="{ name: CUSTOMERS_ROUTE }"><li :class="[ [CUSTOMERS_ROUTE].includes(route) ? 'selected' : null, 'nav-items' ]"><i class="pi pi-id-card"></i> Customers</li></router-link>
                <router-link v-if="isAdmin" :to="{ name: STAFF_ROUTE }"><li :class="[ [STAFF_ROUTE, STAFF_PROFILE_ROUTE].includes(route) ? 'selected' : null, 'nav-items' ]"><i class="pi pi-id-card"></i> Staff</li></router-link>
                <router-link v-if="isAdmin" :to="{ name: SUBSCRIPTIONS_ROUTE }"><li :class="[ [SUBSCRIPTIONS_ROUTE].includes(route) ? 'selected' : null, 'nav-items' ]"><i class="pi pi-ticket"></i> Subscriptions</li></router-link>
                <router-link v-if="isAdmin" :to="{ name: SETTINGS_ROUTE }"><li :class="[ [SETTINGS_ROUTE].includes(route) ? 'selected' : null, 'nav-items' ]"><i class="pi pi-cog"></i> Settings</li></router-link>
            </ul>
        </div>
        <div v-on:click="routeToProfile()" class="account">
            <hr>
            <div class="row">
                <div class="col-3 primary-letter">
                    <span class="letter">{{ $filters.firstLetter(account.fname) }}<span class="online"></span></span>
                </div>
                <div class="col-8">
                    <p class="name">{{ account.fname }} {{ account.lname }}</p>
                    <p class="email text-secondary">{{ account.email }}</p>
                </div>
            </div>
        </div>
        <v-tour name="myTour" :steps="steps"></v-tour>
    </div>
</template>
<script>
import {
    DASHBOARD_ROUTE,
    APPOINTMENTS_ROUTE,
    SERVICES_ROUTE,
    STAFF_ROUTE,
    STAFF_PROFILE_ROUTE,
    CUSTOMERS_ROUTE,
    SUBSCRIPTIONS_ROUTE,
    SETTINGS_ROUTE,
    PROFILE_ROUTE
} from '@/utils/routes'
import { ACCOUNT_ROLES } from '@/utils/const';
import { GET_MY_COMPANIES, SWITCH_COMPANY } from '@/store/action_types';

export default {
    data() {
        return  {
            DASHBOARD_ROUTE,
            APPOINTMENTS_ROUTE,
            SERVICES_ROUTE,
            STAFF_ROUTE,
            STAFF_PROFILE_ROUTE,
            CUSTOMERS_ROUTE,
            SUBSCRIPTIONS_ROUTE,
            SETTINGS_ROUTE,
            ACCOUNT_ROLES,
            myCompaniesList: [],
            isAdmin: false,
            steps: [
          {
            target: '#v-step-0',  // We're using document.querySelector() under the hood
            header: {
              title: 'Get Started',
            },
            content: `Discover <strong>Vue Tour</strong>!`
          },
          {
            target: '.v-step-1',
            content: 'An awesome plugin made with Vue.js!'
          },
          {
            target: '[data-v-step="2"]',
            content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
            params: {
              placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            }
          }
        ]
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        account() {
            return this.$store.getters.account.data
        },
        accountIsLoading() {
            return this.$store.getters.account.isLoading
        },
        myCompanies() {
            return this.$store.getters.myCompanies.data
        },
        myCompaniesIsLoading() {
            return this.$store.getters.myCompanies.isLoading
        }
    },
    methods: {
        routeToProfile() {
            this.$router.push({ name: PROFILE_ROUTE })
        },
        switchCompany(id) {
            this.$store.dispatch(SWITCH_COMPANY, { _id: id }).then((result) => {
                if(result) {
                    this.$router.go()
                }
            })
        },
        checkIsAdmin() {
            [ACCOUNT_ROLES.admin, ACCOUNT_ROLES.root, ACCOUNT_ROLES.superAdmin].filter(value => this.account.roles.includes(value)) != [] ? this.isAdmin = true : this.isAdmin = false
        },
        getMyCompanies() {
            this.$store.dispatch(GET_MY_COMPANIES).then((result) => {
                if(result) {
                    result.forEach((e) => {
                        console.log('ddd', e)
                        if(e._id != this.account.sessionCompany._id) {
                            this.myCompaniesList.push({ _id: e._id, name: e.name})
                        }
                    })
                }
            })
        }
    },
    mounted() {
        this.checkIsAdmin()
        // this.$tours['myTour'].start()
        this.getMyCompanies()
    }
}
</script>
<style scoped>  
.dropdown-btn {
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
    z-index: 0.1;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
}
.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
  
.dropdown-content a {
    color: black;
    padding: 6px 16px;
    text-decoration: none;
    display: block;
    border-radius: 10px;
}
  
.dropdown-content a:hover {
    cursor: pointer;
    background-color: #eef0f2;
}
.dropdown:hover .dropdown-content {display: block;}

.pi-chevron-down {
    margin-left: 15px;
}
</style>
  