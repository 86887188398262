// account
export const SIGNUP_URL = '/auth/signup'
export const LOGIN_URL = '/auth/login'
export const LOGOUT_URL = '/auth/logout'
export const GET_AUTH_ACCOUNT_URL = '/account/auth'
export const EDIT_ACCOUNT_URL = '/account'

// appointment
export const GET_APPOINTMENTS_URL = '/appointment'
export const GET_CALENDAR_APPOINTMENTS_URL = '/appointment/calendar'
export const ADD_APPOINTMENT_URL = '/appointment'
export const REQUEST_APPOINTMENT_URL = '/appointment/request'
export const EDIT_APPOINTMENT_URL = '/appointment'
export const EDIT_APPOINTMENT_STATUS_URL = '/appointment/status'
export const DELETE_APPOINTMENT_URL = '/appointment'

// customer
export const GET_CUSTOMERS_URL = '/customer'
export const GET_CUSTOMER_APPOINTMENTS_URL = '/customer/appointment'
export const ADD_CUSTOMER_URL = '/customer'
export const EDIT_CUSTOMER_URL = '/customer'
export const DELETE_CUSTOMER_URL = '/customer'

// service
export const GET_SERVICES_URL = '/service'
export const GET_SERVICES_PUBLIC_URL = '/service/public'
export const ADD_SERVICE_URL = '/service'
export const EDIT_SERVICE_URL = '/service'
export const DELETE_SERVICE_URL = '/service'

// category
export const GET_CATEGORIES_URL = '/category'
export const ADD_CATEGORY_URL = '/category'
export const EDIT_CATEGORY_URL = '/category'
export const DELETE_CATEGORY_URL = '/category'

// subscription
export const GET_SUBSCRIPTIONS_URL = '/subscription'
export const CHECK_SUBSCRIPTION_PLAN_PURCHASED_URL = '/subscription/checkIfSubscriptionPlanIsPurchased'
export const ADD_SUBSCRIPTION_URL = '/subscription'
export const EDIT_SUBSCRIPTION_URL = '/subscription'
export const DELETE_SUBSCRIPTION_URL = '/subscription'

// staff
export const GET_STAFFS_URL = '/account'
export const ADD_STAFF_URL = '/account'
export const EDIT_STAFF_URL = '/account'
export const TOGGLE_STAFF_STATUS_URL = '/account/toggleActiveStatus'
export const DELETE_STAFF_URL = '/account'

// company
export const GET_COMPANY_INFO_URL = '/company'
export const GET_MY_COMPANY_INFO_URL = '/company/my'
export const GET_MY_COMPANIES_URL = '/company/my/list'
export const SWITCH_COMPANY_URL = '/company/my/switch'
export const EDIT_COMPANY_INFO_URL = '/company'

// activities
export const GET_ACTIVITIES_URL = '/sessionActivity'

// payment
export const REQUEST_CHAPA_PAYMENT_URL = '/payment/chapa/request'
export const CHECK_PAYMENT_CONFIRMATON_URL = '/payment/checkConfirmation'

// notification
export const GET_UNREAD_NOTIFICATON_URL = '/notification/unread'

// stats
export const GET_DASHBOARD_STATS_URL = '/stats/dashboard'

// sms
export const GET_SMS_URL = '/smsResult'

// otp_verification
export const OTP_REQUEST_URL = '/customer/requestOTP'
export const OTP_VERIFICATION_URL = '/customer/verifyOTP'
export const CHECK_OTP_EXPIRY_URL = '/customer/checkOTPExpiry'