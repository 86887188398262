import baseAPI from '@/api/base';
import { LoginCredentals, RegistrationCredentals } from '../dtos/auth.dto';
import { EDIT_ACCOUNT_URL, GET_AUTH_ACCOUNT_URL, LOGIN_URL, LOGOUT_URL, SIGNUP_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { EditAccountDto } from '@/dtos/account.dto';

class Auth {
    static login = async(loginCredentals: LoginCredentals) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(LOGIN_URL, {
                    ...loginCredentals
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }

    static signup = async(registrationCredentals: RegistrationCredentals) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(SIGNUP_URL, {
                    ...registrationCredentals
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }

    static editAccount = async(editAccountDto: EditAccountDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_ACCOUNT_URL, {
                    ...editAccountDto
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })
    }

    static logout = async() : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(LOGOUT_URL)
                console.log('response', response)
                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }

    static getAuthAccount = async() : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_AUTH_ACCOUNT_URL)
                console.log('response', response)
                resolve(response)
            } catch (error) {
                // console.log('error', error)
                reject(error)
            }
        })
    }
}

export default Auth