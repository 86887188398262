import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_SUBSCRIPTION, SET_SUBSCRIPTIONS, SET_SUBSCRIPTION_PLAN_PURCHASE_CHECK, SET_ERRORMSG } from '../mutation_types'
import {
    GET_SUBSCRIPTION,
    GET_SUBSCRIPTIONS,
    CHECK_IF_SUBSCRIPTION_PLAN_IS_PURCHASED,
    CLEAR_SUBSCRIPTIONS_ISLOADING,
    CLEAR_SUBSCRIPTIONS
} from '../action_types'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import Subscription from '@/services/subscription'
import { GetSubscriptionDto } from '@/dtos/subscription.dto'

const subscription: Module<any, {}> = {
    state: {
        subscription: {
            data: null,
            status: null,
            isLoading: false
        },
        subscriptions: {
            data: null,
            isLoading: false
        },
        subscriptionPlanPurchasedCheck: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        subscription: (state: { subscription: any }) => state.subscription,
        subscriptions: (state: { subscriptions: any }) => state.subscriptions,
        subscriptionPlanPurchasedCheck: (state: { subscriptionPlanPurchasedCheck: any }) => state.subscriptionPlanPurchasedCheck
    },
    mutations: {
        [SET_SUBSCRIPTION]: (state: { subscription: StateObject }, payload: { data: null, isLoading: any }) => { payload.data != null ? state.subscription.data = payload.data : null, state.subscription.isLoading = payload.isLoading },
        [SET_SUBSCRIPTIONS]: (state: { subscriptions: StateObject }, payload: { data: null, isLoading: any }) => { payload.data != null ? state.subscriptions.data = payload.data : null, state.subscriptions.isLoading = payload.isLoading },
        [SET_SUBSCRIPTION_PLAN_PURCHASE_CHECK]: (state: { subscriptionPlanPurchasedCheck: StateObject }, payload: { data: null, isLoading: any }) => { payload.data != null ? state.subscriptionPlanPurchasedCheck.data = payload.data : null, state.subscriptionPlanPurchasedCheck.isLoading = payload.isLoading }
    },
    actions: {
        [GET_SUBSCRIPTIONS]: async({ commit }: any, payload: GetSubscriptionDto) => {
            commit(SET_SUBSCRIPTIONS, { data: null, isLoading: true })

            let result: AxiosResponse = await Subscription.getSubscriptions(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_SUBSCRIPTIONS, { data: result.data.data, isLoading: false })
                    return
                }
                commit(SET_SUBSCRIPTIONS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_SUBSCRIPTIONS, { isLoading: false })
            }
        },
        [CHECK_IF_SUBSCRIPTION_PLAN_IS_PURCHASED]: async({ commit }: any, payload: GetSubscriptionDto) => {
            commit(SET_SUBSCRIPTION_PLAN_PURCHASE_CHECK, { data: null, isLoading: true })

            let result: AxiosResponse = await Subscription.checkIfSubscriptionPlanIsPurchased(payload)
            console.log('CHECK_IF_SUBSCRIPTION_PLAN_IS_PURCHASED result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_SUBSCRIPTION_PLAN_PURCHASE_CHECK, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_SUBSCRIPTION_PLAN_PURCHASE_CHECK, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_SUBSCRIPTION_PLAN_PURCHASE_CHECK, { isLoading: false })
            }
            return null
        },
        [CLEAR_SUBSCRIPTIONS_ISLOADING]: async({ commit }) => {
            commit(SET_SUBSCRIPTION, { isLoading: false })
            commit(SET_SUBSCRIPTION_PLAN_PURCHASE_CHECK, { isLoading: false })
        },
        [CLEAR_SUBSCRIPTIONS]: async({ commit }) => {
            commit(SET_SUBSCRIPTION, { data: null, isLoading: false })
            commit(SET_SUBSCRIPTION_PLAN_PURCHASE_CHECK, { data: null, isLoading: false })
        }
    }
}

export default subscription