import baseAPI from '@/api/base';
import { ADD_CATEGORY_URL, GET_CATEGORIES_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { AddCategoryDto, GetCategoryDto } from '@/dtos/category.dto';

class Category {
    static getCategories = async(getCategoryDto: GetCategoryDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_CATEGORIES_URL, {
                    params: {
                        ...getCategoryDto
                    }
                })
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static addCategory = async(addCategoryDto: AddCategoryDto) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_CATEGORY_URL, {
                    ...addCategoryDto
                })
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Category