import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_STAFF, SET_STAFFS, SET_STAFF_APPOINTMENTS, SET_STAFF_ACTIVITIES, SET_ERRORMSG } from '../mutation_types'
import {
    GET_STAFF,
    GET_STAFFS,
    ADD_STAFF,
    EDIT_STAFF,
    DELETE_STAFF,
    GET_STAFF_APPOINTMENTS,
    GET_STAFF_ACTIVITIES,
    CLEAR_STAFFS_ISLOADING,
    TOGGLE_STAFF_STATUS,
    CLEAR_STAFFS
} from '../action_types'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import Staff from '@/services/staff'
import { AddStaffDto, GetStaffDto } from '@/dtos/staff.dto'
import { GetAppointmentDto } from '@/dtos/appointment.dto'
import { GetActivityDto } from '@/dtos/activity.dto'

const staff: Module<any, {}> = {
    state: {
        staff: {
            data: null,
            isLoading: false
        },
        staffs: {
            data: null,
            isLoading: false
        },
        staffAppointments: {
            data: null,
            isLoading: false
        },
        staffActivities: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        staff: (state: { staff: any }) => state.staff,
        staffs: (state: { staffs: any }) => state.staffs,
        staffAppointments: (state: { staffAppointments: any }) => state.staffAppointments,
        staffActivities: (state: { staffActivities: any }) => state.staffActivities
    },
    mutations: {
        [SET_STAFF]: (state: { staff: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.staff.data = payload.data : null, state.staff.isLoading = payload.isLoading },
        [SET_STAFFS]: (state: { staffs: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.staffs.data = payload.data : null, state.staffs.isLoading = payload.isLoading },
        [SET_STAFF_APPOINTMENTS]: (state: { staffAppointments: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.staffAppointments.data = payload.data : null, state.staffAppointments.isLoading = payload.isLoading },
        [SET_STAFF_ACTIVITIES]: (state: { staffActivities: StateObject }, payload: { data: null; isLoading: any }) => { payload.data != null ? state.staffActivities.data = payload.data : null, state.staffActivities.isLoading = payload.isLoading }
    },
    actions: {
        [GET_STAFFS]: async({ commit }: any, payload: any) => {
            commit(SET_STAFFS, { data: null, isLoading: true })

            let result: AxiosResponse = await Staff.getStaffs(payload)
            console.log('result custt', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_STAFFS, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_STAFFS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_STAFFS, { isLoading: false })
            }
            return []
        },
        [GET_STAFF]: async({ commit }: any, payload: any) => {
            commit(SET_STAFF, { data: null, isLoading: true })

            let result: AxiosResponse = await Staff.getStaffs(payload as GetStaffDto)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_STAFF, { data: result.data.data[0], isLoading: false })
                    return
                }
                commit(SET_STAFF, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_STAFF, { isLoading: false })
            }
        },
        [GET_STAFF_APPOINTMENTS]: async({ commit }: any, payload: any) => {
            commit(SET_STAFF_APPOINTMENTS, { data: null, isLoading: true })

            let result: AxiosResponse = await Staff.getStaffAppointments(payload as GetAppointmentDto)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_STAFF_APPOINTMENTS, { data: result.data.data, isLoading: false })
                    return
                }
                commit(SET_STAFF_APPOINTMENTS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_STAFF_APPOINTMENTS, { isLoading: false })
            }
        },
        [GET_STAFF_ACTIVITIES]: async({ commit }: any, payload: any) => {
            commit(SET_STAFF_ACTIVITIES, { data: null, isLoading: true })

            let result: AxiosResponse = await Staff.getStaffActivities(payload as GetActivityDto)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_STAFF_ACTIVITIES, { data: result.data.data, isLoading: false })
                    return
                }
                commit(SET_STAFF_ACTIVITIES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_STAFF_ACTIVITIES, { isLoading: false })
            }
        },
        [ADD_STAFF]: async({ commit }: any, payload: any) => {
            commit(SET_STAFF, { data: null, isLoading: true })

            let result: AxiosResponse = await Staff.addStaff(payload as AddStaffDto)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_STAFF, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_STAFF, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_STAFF, { isLoading: false })
            }
            return null
        },
        [EDIT_STAFF]: async({ commit }: any, payload: any) => {
            commit(SET_STAFF, { data: null, isLoading: true })

            let result: AxiosResponse = await Staff.editStaff(payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_STAFF, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_STAFF, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_STAFF, { isLoading: false })
            }
            return null
        },
        [TOGGLE_STAFF_STATUS]: async({ commit }: any, payload: any) => {
            commit(SET_STAFF, { data: null, isLoading: true })

            let result: AxiosResponse = await Staff.toggleStaffStatus(payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_STAFF, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_STAFF, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_STAFF, { isLoading: false })
            }
            return null
        },
        [DELETE_STAFF]: async({ commit }: any, payload: any) => {
            commit(SET_STAFF, { data: null, isLoading: true })

            let result: AxiosResponse = await Staff.deleteStaff(payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_STAFF, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_STAFF, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_STAFF, { isLoading: false })
            }
            return null
        },
        [CLEAR_STAFFS_ISLOADING]: ({ commit }) => {
            commit(SET_STAFF, { isLoading: false })
            commit(SET_STAFFS, { isLoading: false })
        },
        [CLEAR_STAFFS]: ({ commit }) => {
            commit(SET_STAFF, { data: null, isLoading: false })
            commit(SET_STAFFS, { data: null, isLoading: false })
        }
    }
}

export default staff