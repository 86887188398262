<template>
    <div class="">
        <errorNav/>
        <div class="row content">
            <sidenav class="col-2"/>
            <div class="col-10 content-section dashboard">
                <div class="demo-container">
                    <div class="progress-bar">
                        <div v-if="dashboardStatsIsLoading" class="progress-bar-value"></div>
                    </div>
                </div>
                <div v-if="dashboardStats" class="row">
                    <div class="col-9">
                        <div class="row">
                            <div class="col-3">
                                <div class="card stats-card">
                                    <div class="card-body">
                                        <h4 class="value"><span>{{ dashboardStats.appointmentsThisMonth }} / {{ dashboardStats.appointmentsToday }}</span><small> (this month / today)</small></h4>
                                        <h6 class="text-secondary title"><strong>Appointments</strong></h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="card stats-card">
                                    <div class="card-body">
                                        <h4 class="value"><span>{{ dashboardStats.totalCustomers }}</span></h4>
                                        <h6 class="text-secondary"><strong>Customers</strong></h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="card stats-card">
                                    <div class="card-body">
                                        <h4 class="value"><span>{{ dashboardStats.totalServices }}</span></h4>
                                        <h6 class="text-secondary"><strong>Services</strong></h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="card stats-card">
                                    <div class="card-body">
                                        <h4 class="value"><span>{{ dashboardStats.totalSMSAlerts }} / {{ dashboardStats.totalEmailAlerts }}</span><small> (SMS / Email)</small></h4>
                                        <h6 class="text-secondary"><strong>Alerts</strong></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-12">
                                <div class="card chart-card">
                                    <h5>Appointments Trend</h5>
                                    <hr>
                                    <apexchart ref="usersChart" height="300"  type="area" :options="chartOptions" :series="series"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card staff-card">
                            <div class="card-body">
                                <h5>Staff</h5>
                                <hr>
                                <template v-bind:key="index" v-for="(staff, index) in dashboardStats.staff">
                                    <div class="row staff-row">
                                        <div class="col-2">
                                            <i class="pi pi-user"></i>
                                        </div>
                                        <div class="col-9">
                                            <p class="user"><Strong>{{ $filters.capitalize(staff.fname) }} {{ $filters.capitalize(staff.lname) }}</Strong></p>
                                            <small class="role">{{ $filters.capitalize(staff.roles.join(', ')) }}</small>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <br>
                        <div class="card subscription-card">
                            <div class="card-body">
                                <h5>Subscription</h5>
                                <hr>
                                <div class="row">
                                    <div v-if="myCompanyInfo.hasActiveSubscription" class="col-12">
                                        <p><strong class="title">Subscription Plan:</strong> <small>1 Year</small></p>
                                        <p><strong class="title">Next-Payment-Due:</strong> <small>{{ $filters.getDateOnly(myCompanyInfo.lastActiveSubscription.endDate) }}</small></p>
                                    </div>
                                    <div v-else class="col-12">
                                        <a v-on:click="goToSubscriptionsPage()" class="text-primary subscriptions-link"><strong>Start Subscription Now (Click Here)</strong></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div v-if="myCompanyInfo" class="card subscription-card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <h5>Appointment Request Link</h5>
                                    </div>
                                </div>
                                <hr>
                                <small class="description">Share the link below for your clients to request an appointment.</small>
                                <br>
                                <br>
                                <div class="input-group">
                                    <input class="form-control" style="font-size: 13px;" type="text" :placeholder="`${APP_URL}/appointment-request/${myCompanyInfo._id}`" readonly>
                                    <div v-on:click="copyToClipboard(`${APP_URL}/appointment-request/${myCompanyInfo._id}`)" class="input-group-append show-password">
                                        <span class="input-group-text" style="font-size: 13px;"><i class="pi pi-copy"></i> copy</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'
import sidenav from "@/components/Sidenav.vue";
import errorNav from '@/components/Error_Nav.vue'
import { GET_DASHBOARD_STATS, GET_STAFFS, GET_MY_COMPANY_INFO } from '../store/action_types'
import Appointment from '../services/appointment';
import { SUBSCRIPTION_OPTION_ROUTE } from '@/utils/routes';

export default {
    title: 'Dashboard',
    components: {
        sidenav,
        errorNav,
        apexchart: VueApexCharts
    },
    data () {
        return {
            APP_URL: process.env.VUE_APP_URL,
            series: [{
                name: 'series1',
                data: []
            },],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'area'
                },
                colors: ['#007bff'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    categories: []
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
        }
    },
    computed: {
        account() {
            return this.$store.getters.account.data
        },
        accountIsLoading() {
            return this.$store.getters.account.isLoading
        },
        staffs() {
            return this.$store.getters.staffs.data
        },
        staffsIsLoading() {
            return this.$store.getters.staffs.isLoading
        },
        dashboardStats() {
            return this.$store.getters.dashboardStats.data
        },
        dashboardStatsIsLoading() {
            return this.$store.getters.dashboardStats.isLoading
        },
        myCompanyInfo() {
            return this.$store.getters.myCompanyInfo.data
        },
        myCompanyInfoIsLoading() {
            return this.$store.getters.myCompanyInfo.isLoading
        }
    },
    methods: {
        updateSeriesLine() {
            this.series = [{
                data: this.dashboardStats.last10Days.counts
            }]
            this.chartOptions = {
                xaxis: {
                    categories: this.dashboardStats.last10Days.weekDays
                },
            };

        },
        copyToClipboard(value) {
            this.$clipboard(value)
            this.$toast.success('copied to clipboard')
        },
        goToSubscriptionsPage() {
            this.$router.push({ name: SUBSCRIPTION_OPTION_ROUTE, params: { account: this.account._id, company: this.myCompanyInfo._id } })
        },
        getStaffs() {
            this.$store.dispatch(GET_STAFFS)
        },
        getMyCompanyInfo() {
            this.$store.dispatch(GET_MY_COMPANY_INFO)
        },
        getDashboardStats() {
            this.$store.dispatch(GET_DASHBOARD_STATS).then(() => this.updateSeriesLine())
        }
    },
    created() {
        this.getDashboardStats()
        this.getStaffs()
        this.getMyCompanyInfo()
    }
}
</script>
<style scoped>

.demo-container {
    height: 3px;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 10px;
}

.progress-bar {
    
    height: 3px;
    background-color: transparent;
    overflow: hidden;
    width: 100%;
    border-radius: 10px;
}

.progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: #1C88FB;
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
    0% {
    transform:  translateX(0) scaleX(0);
    }
    40% {
    transform:  translateX(0) scaleX(0.4);
    }
    100% {
    transform:  translateX(100%) scaleX(0.5);
    }
}
.subscriptions-link:hover {
    cursor: pointer;
    text-decoration: underline;
}
</style>