import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_CATEGORY, SET_CATEGORIES, SET_ERRORMSG } from '../mutation_types'
import {
    GET_CATEGORY,
    GET_CATEGORIES,
    ADD_CATEGORY,
    EDIT_CATEGORY,
    DELETE_CATEGORY,
    CLEAR_CATEGORIES_ISLOADING,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import { Module } from 'vuex'
import Category from '@/services/category'
import { GetCategoryDto } from '@/dtos/category.dto'

const category: Module<any, {}> = {
    state: {
        category: {
            data: null,
            isLoading: false
        },
        categories: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        category: (state: { category: any }) => state.category,
        categories: (state: { categories: any }) => state.categories
    },
    mutations: {
        [SET_CATEGORY]: (state: { category: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.category.data = payload.data : null, state.category.isLoading = payload.isLoading },
        [SET_CATEGORIES]: (state: { categories: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.categories.data = payload.data : null, state.categories.isLoading = payload.isLoading },
    },
    actions: {
        [GET_CATEGORIES]: async({ commit }: any, payload: GetCategoryDto) => {
            commit(SET_CATEGORIES, { data: null, isLoading: true })

            let result: AxiosResponse = await Category.getCategories(payload)
            console.log('result', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_CATEGORIES, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_CATEGORIES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CATEGORIES, { isLoading: false })
            }
            return []
        },
        [ADD_CATEGORY]: async({ commit }: any, payload: GetCategoryDto) => {
            commit(SET_CATEGORY, { data: null, isLoading: true })

            let result: AxiosResponse = await Category.addCategory(payload)
            console.log('result ADD CAT', result)
            console.log('payload', payload)

            if(result) {
                if (result.data.status) {
                    commit(SET_CATEGORY, { data: result.data.data, isLoading: false })
                    return result.data.data
                }
                commit(SET_CATEGORY, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
            } else {
                commit(SET_CATEGORY, { isLoading: false })
            }
            return null
        },
        [CLEAR_CATEGORIES_ISLOADING]: ({ commit }) => {
            commit(SET_CATEGORY, { isLoading: false })
            commit(SET_CATEGORIES, { isLoading: false })
        }
    }
}

export default category